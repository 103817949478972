<template>
    <v-list-item v-if="type === 'listItem'">
        <v-list-item-title>
            <v-btn class="justify-start" color="secondary" text block v-clipboard:copy="textToCopy" v-clipboard:success="onSuccess" @click="itemClicked = true">
                <v-icon left>{{ icon }}</v-icon>
                {{ buttonName }}
            </v-btn>
        </v-list-item-title>
    </v-list-item>
    <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :color="buttonColor"
                :class="buttonClass"
                :text="isTextButton"
                v-clipboard:copy="textToCopy"
                v-clipboard:success="onSuccess"
                v-clipboard:error="onFail"
                :icon="iconButton"
                v-if="type === 'button'"
                :disabled="disabled"
                :small="isSmall"
                v-bind="attrs"
                v-on="on">
                <v-icon v-if="showIcon" :small="isSmall" :left="isTextButton">{{ icon }}</v-icon>
                <span v-if="!iconButton">{{ buttonName }}</span>
            </v-btn>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>
<script>
export default {
    name: 'CopyToClipboard',
    props: {
        textToCopy: String,
        buttonClass: String,
        buttonColor: String,
        isTextButton: { type: Boolean, default: false },
        buttonName: String,
        iconButton: { type: Boolean, default: false },
        showIcon: { type: Boolean, default: true },
        type: { type: String, default: 'button' },
        disabled: { type: Boolean, default: false },
        isSmall: { type: Boolean, default: true },
        tooltip: { type: String, default: 'Copy to clipboard' },
        icon: { type: String, default: 'mdi-content-copy' }
    },
    data() {
        return {
            itemClicked: false
        }
    },
    methods: {
        onSuccess: function () {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Copied to clipboard!',
                snackBarTimeout: 5000,
                snackBarIcon: 'check_circle'
            })
        },
        onFail: function () {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Failed to copy to clipboard',
                snackBarTimeout: 5000,
                snackBarIcon: 'error'
            })
        }
    }
}
</script>
